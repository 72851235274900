import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gradingMachineListResetTimestamp: ['procType', 'currentPage', 'sorts', 'filters', 'pageSize'],
  gradingMachineListGoToResource: ['resId'],
  gradingMachineListNewResource: null,
  gradingMachineListGoToAudit: ['hdrId'],
  gradingMachineListFetchList: ['currentPage', 'sorts', 'filters', 'pageSize'],
  gradingMachineListFetchListLoading: ['boolean'],
  gradingMachineListFetchListSuccess: ['documents', 'currentPage', 'lastPage', 'total', 'pageSize'],
  gradingMachineListAddSelectedDocuments: ['selectedDocuments'],
  gradingMachineListRemoveSelectedDocuments: ['selectedDocuments'],
  gradingMachineListSetWorkspaceVisible: ['boolean']
});

export const GradingMachineTypes = Types;
export default Creators;
