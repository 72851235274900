import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gradingMachineProcessFetchList: null,
  gradingMachineProcessFetchListLoading: ['boolean'],
  gradingMachineProcessFetchListSuccess: ['process']
});

export const GradingMachineProcessTypes = Types;
export default Creators;
