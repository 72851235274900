import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  gradingMachineExcelShowBatchJobStatus: null,
  gradingMachineExcelShowBatchJobStatusSuccess: ['batchJobStatus'],
  gradingMachineExcelUploadExcel: ['file'],
  gradingMachineExcelUploadLoading: ['boolean'],
  gradingMachineExcelDownloadExcel: []
});

export const GradingMachineExcelTypes = Types;
export default Creators;
