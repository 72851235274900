/**
 * Reducers specify how the application's state changes in response to actions sent to the store.
 *
 * @see https://redux.js.org/basics/reducers
 */

import { createReducer } from 'reduxsauce';
import { GradingMachineTypes } from './Actions';
import INITIAL_STATE from './InitialState';

export const gradingMachineListResetTimestamp = (
  state,
  { procType, currentPage, sorts, filters, pageSize }
) => ({
  ...state,
  procType: procType || state.procType,
  currentPage: currentPage || state.currentPage,
  sorts: sorts || state.sorts,
  filters: filters || state.filters,
  pageSize: pageSize || state.pageSize,
  timestamp: Date.now()
  // selectedDocuments: INITIAL_STATE.selectedDocuments,
  // toStorageBinOptions: INITIAL_STATE.toStorageBinOptions,
  // toStorageBinOption: INITIAL_STATE.toStorageBinOption
});

export const gradingMachineListFetchListLoading = (state, { boolean }) => ({
  ...state,
  fetchIsLoading: boolean
});

export const gradingMachineListFetchListSuccess = (
  state,
  { documents, currentPage, lastPage, total, pageSize }
) => ({
  ...state,
  documents,
  currentPage,
  lastPage,
  total,
  pageSize
});

export const gradingMachineListAddSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = state.selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: [
      ...state.selectedDocuments,
      ...selectedDocuments.filter(data => {
        return !selectIds.includes(data.id);
      })
    ]
  };
};

export const gradingMachineListRemoveSelectedDocuments = (state, { selectedDocuments }) => {
  const selectIds = selectedDocuments.map(value => value.id);
  return {
    ...state,
    selectedDocuments: state.selectedDocuments.filter(data => {
      return !selectIds.includes(data.id);
    })
  };
};

export const gradingMachineListSetWorkspaceVisible = (state, { boolean }) => ({
  ...state,
  workspaceIsVisible: boolean
});

/**
 * @see https://github.com/infinitered/reduxsauce#createreducer
 */
export const reducer = createReducer(INITIAL_STATE, {
  [GradingMachineTypes.GRADING_MACHINE_LIST_RESET_TIMESTAMP]: gradingMachineListResetTimestamp,
  [GradingMachineTypes.GRADING_MACHINE_LIST_FETCH_LIST_LOADING]: gradingMachineListFetchListLoading,
  [GradingMachineTypes.GRADING_MACHINE_LIST_FETCH_LIST_SUCCESS]: gradingMachineListFetchListSuccess,
  [GradingMachineTypes.GRADING_MACHINE_LIST_ADD_SELECTED_DOCUMENTS]: gradingMachineListAddSelectedDocuments,
  [GradingMachineTypes.GRADING_MACHINE_LIST_REMOVE_SELECTED_DOCUMENTS]: gradingMachineListRemoveSelectedDocuments,
  [GradingMachineTypes.GRADING_MACHINE_LIST_SET_WORKSPACE_VISIBLE]: gradingMachineListSetWorkspaceVisible
});
